import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class UKChecklistOverseas extends React.Component {
    constructor(props) {
      
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="UKChecklistOverseas"  >
       <p>I want to provide you with a quick overview of what you need to consider if you are an overseas-qualified Architect (Built Environment) planning to relocate to the UK.</p>
        <p><strong>The Structure of this Guide</strong></p>
        <ol>
        <li>ARB, RIBA and Job Titles</li>
        <li>Locations</li>
        <li>Skilled Worker Visa</li>
        <li>Salaries</li>
        <li>UK Employee Benefits</li>
        <li>Finding Architecture Jobs in the UK</li>
        <li>Application Documents: CV, Portfolio, and Cover Letters</li>
        </ol>
        <p/>
        <h3>Section 1: ARB, RIBA and Job Titles</h3>
        <p><strong>Can I Use the Title &ldquo;Architect&rdquo; Without ARB Registration?</strong><strong><br/></strong>No, you cannot use the title "Architect" unless registered with the ARB. The title is legally protected in the UK.</p>
        <p><strong>How Can I Register with the ARB as an Overseas-Qualified Architect?</strong><strong><br/></strong>If you have overseas qualifications (outside the EU) or non-recognised UK qualifications, you’ll need to pass the ARB's Prescribed Examination to register as an architect in the UK. After completing Part 1 and Part 2 requirements (either by obtaining the right qualifications or passing the exam), you’ll need a UK Part 3 qualification from a UK architecture school.</p>
        <p>EU qualifications at degree level count as Part 1, but you must still take the Part 1 examination. Part 1 requires a three-year course, and Part 2 requires an additional two years of postgraduate study. If your qualifications are not primarily in architecture (e.g., Interior Design), you may not be eligible to sit the exam unless under transitional arrangements. For details, visit the <a href="https://arb.org.uk/architect-information/applying-for-registration-for-the-first-time/i-hold-overseas-non-recognised-uk-qualifications/#:~:text=Currently%20if%20you%20hold%20overseas,an%20architect%20in%20the%20UK.">ARB website</a>.</p>
        <p><strong>Mutual Recognition Agreements with Other Countries</strong><strong><br/></strong>In 2023, the ARB signed Mutual Recognition Agreements (MRAs) with the US (NCARB), Australia (AACA), and New Zealand (NZRAB), providing eligible architects with a pathway to licensure in the UK.</p>
        <p>In 2024, a new Memorandum of Understanding (MoU) between the Hong Kong Institute of Architects (HKIA) and the ARB enables the mutual recognition of qualifications, simplifying the process for eligible individuals to have their qualifications recognised in both the UK and Hong Kong.</p>
        <p>For details on eligibility and application, <a href="https://arb.org.uk/international-routes/">ARB website</a>.</p>
        <p><strong>What is the Difference Between ARB and RIBA?</strong></p>
        <ul>
        <li><strong>ARB</strong>: The regulatory body ensures architects meet requirements to practice legally and use the title "Architect."</li>
        <li><strong>RIBA</strong>: A voluntary membership organisation offering resources, support, and development opportunities.</li>
        </ul>
        <p>In essence, ARB registration is mandatory to use the title, while RIBA membership is optional.</p>
        <p><strong>Common Architecture Job Titles in the UK</strong></p>
        <ul>
        <li>Part 1 Architectural Assistant (Bachelor&rsquo;s Degree)</li>
        <li>Part 2 Architectural Assistant (Master&rsquo;s Degree)</li>
        <li>Architectural Assistant</li>
        <li>Part 3 Architect</li>
        <li>Architectural Designer (unprotected title)</li>
        <li>Architect (protected title)</li>
        <li>Landscape Architect</li>
        <li>Project Architect</li>
        <li>Senior Architect</li>
        <li>Associate/Principal Architect</li>
        </ul>
        <p><em>Note</em>: The title &ldquo;Junior Architect&rdquo; isn&rsquo;t used in the UK. Avoid referring to yourself with this title when applying for jobs.</p>
        <p/>
        <p/>
        <h3><strong>Section 2: Locations</strong></h3>
        <p><strong>London</strong><strong><br/></strong>London attracts many job seekers, as numerous architecture firms are based here. However, competition is fierce, the job search may take longer, and living costs are high. Commutes can exceed 1.5 hours. Use tools like <a href="https://www.numbeo.com/cost-of-living/compare_cities.jsp?country1=Spain&amp;country2=United+Kingdom&amp;city1=Barcelona&amp;city2=London&amp;tracking=getDispatchComparison">Cost of Living</a> to compare expenses across cities.</p>
        <p><strong>Other UK Cities</strong><strong><br/></strong>Cities like Manchester, Bristol, Leeds, Birmingham, Glasgow, Newcastle, and Edinburgh offer job opportunities with a lower cost of living than London.</p>
        <p><strong>Rural Areas</strong><strong><br/></strong>In rural areas, expect fewer job options, smaller firms, and longer commutes. A car may be necessary, and public transport can be costly. Research thoroughly, as some rural areas, such as the South of England, can be surprisingly expensive.</p>
        <p/>
        <p/>
        <h3><strong>Section 3: Skilled Worker Visa</strong></h3>
        <p><strong>Skilled Worker Visa Salary Thresholds (2024)</strong><strong><br/></strong>New thresholds (effective 4th April 2024):</p>
        <ul>
        <li><strong>Architects/Landscape Architects (Code 2451):</strong> Minimum &pound;45,900 per annum</li>
        <li><strong>Chartered Architectural Technologists/Consultants (Code 2452):</strong> Minimum &pound;38,700 per annum (though the going rate is &pound;36,200)</li>
        <li><strong>Architectural Assistants/Technicians (Code 3120):</strong> Minimum &pound;38,700 per annum (though the going rate is &pound;31,200)</li>
        </ul>
        <p>Source: <a href="https://assets.publishing.service.gov.uk/media/65f18e57ff11701fff6159bb/E03091226_-_HC_590_-_Immigration_Rules_Changes__Web_Accessible_.pdf">Statement of Changes in Immigration Rules</a></p>
        <p><strong>Do All Practices Offer Sponsorship in the UK?</strong><strong><br/></strong>No, not all practices offer sponsorships. As a general guideline, smaller practices are less likely to provide sponsorships. The best thing you can do is check the UK Government's “<a target="_blank" rel="noopener" href="https://www.gov.uk/government/publications/register-of-licensed-sponsors-workers">Register of licensed sponsors: workers</a>” list. It's a large file, but it will show you if a practice is in a position to offer sponsorship.</p>

        <p><strong>Where Can I Learn About Right to Work in the UK?</strong><strong><br/></strong>For detailed guidance, visit <a href="https://www.citizensadvice.org.uk/work/right-to-work-in-the-uk/check-if-you-have-the-right-to-work-in-the-uk/#:~:text=You%20automatically%20have%20the%20right,from%20the%20EU%20Settlement%20Scheme"><em>Citizens Advice</em></a> or <em>Gov.uk</em>.</p>
        <p/>
        <p/>
        <h3><strong>Section 4: Salaries</strong></h3>
        <p><strong>Understanding Architectural Salaries</strong><strong><br/></strong>Salaries for architects in the UK are generally lower than in some other countries. London offers higher salaries but also a higher cost of living.</p>
        <ul>
        <li><strong>Junior roles</strong>: Starting at &pound;23,000 per annum</li>
        <li><strong>Average Architect salary</strong>: &pound;37,000&ndash;&pound; 40,000 per annum</li>
        </ul>
        <p>If you require a Skilled Worker Visa, ensure that the minimum salary threshold is met by both you and the employer.</p>
        <p>Note: Most full-time employees work 37.5 - 40 hours per week. Unpaid overtime is common.</p>
        <p/>
        <p/>
        <h3><strong>Section 5: UK Employee Benefits</strong></h3>
        <p><strong>What Benefits Are Employees Entitled To?</strong><strong><br/></strong>UK employees are entitled to:</p>
        <ul>
        <li><strong>Workplace Pension:</strong> Employers contribute 3%, employees contribute 5% (total 8%). Some employers offer more.</li>
        <li><strong>Statutory Sick Pay (SSP):</strong> Paid to eligible employees unable to work due to illness.</li>
        <li><strong>Annual Leave:</strong> A minimum of 28 days (including 8 public holidays).</li>
        <li><strong>Maternity and Paternity Pay:</strong> Up to 39 weeks for maternity and 2 weeks for paternity, subject to eligibility.</li>
        <li><strong>Shared Parental Leave:</strong> Up to 50 weeks of leave and 37 weeks of pay.</li>
        <li><strong>Adoption Leave:</strong> One partner can take up to 52 weeks, and the other may take paternity leave.</li>
        <li><strong>Parental Leave:</strong> Unpaid leave of up to 18 weeks per child (max 4 weeks/year per child).</li>
        </ul>
        <p>For full details, visit the <a href="https://www.gov.uk/browse/working">UK Government website</a>. Optional benefits like hybrid working, healthcare, and bonuses vary by employer.</p>
        <p/>
        <p/>
        <h3><strong>Section 6: Finding Architecture Jobs in the UK</strong></h3>
        <p><strong>Where to Look</strong></p>
        <ul>
        <li><strong>Job Boards:</strong> Use platforms like RIBA Jobs, RIAS, and <a href="https://www.archjobs.co.uk/search">ArchJobs</a></li>
        <li><strong>Company Websites:</strong> Many practices advertise roles directly</li>
        <li><strong>Networking:</strong> Connect with peers and professionals on LinkedIn or at industry events</li>
        <li><strong>LinkedIn:</strong> 77% of recruiters use LinkedIn to find candidates</li>
        <li><strong>Recruiters:</strong> Useful but may be challenging for candidates requiring sponsorship</li>
        </ul>
        <p><strong>Is UK Experience Necessary?</strong><strong><br/></strong>It depends. Practices with international projects may not require UK experience, but many prefer familiarity with UK Building Regulations and Standards.</p>
        <p/>
        <p/>
        <h3><strong>Section 7: Application Documents &ndash; CV, Portfolio and Cover Letters</strong></h3>
        <p>When applying, you&rsquo;ll need:</p>
        <ul>
        <li><strong>CV:</strong> Tailored to the job description</li>
        <li><strong>Sample Portfolio:</strong> A short version of your full portfolio (max 12 pages, under 10MB)</li>
        <li><strong>Cover Letter:</strong> Include if requested</li>
        </ul>
        <p/>
        <p>If you&rsquo;re considering relocating to the UK as an architect, this guide offers a starting point. For more details, read the full <a href="https://www.archjobs.co.uk/articles/moving-to-the-uk-as-an-overseas-architect"><strong>Career Guide: Moving to the UK as an Overseas Architect</strong></a><em>.</em></p>
                </ArticleFrame>
            }
}