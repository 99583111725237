import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class OverseasArchitect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="OverseasArchitect" >
          <p>Many highly talented Overseas Qualified Architects are contemplating relocating to the UK to advance their careers. However, the process is not as straightforward as it may seem, and securing a position can be time-consuming. Some professionals might be unaware that they cannot use their hard-earned title of "Architect" in the UK unless they are registered with the ARB. Moreover, most architectural practices tend to favor candidates with prior experience in the UK, which can be challenging to acquire, especially for those who have never worked in the country before. Additionally, not all practices are in a position to offer sponsorships, and the new changes to the Skilled Worker Visas (effective April 2024) will make it even more challenging.</p>
          <p>In this article, my aim is to highlight the essential factors you should be aware of before embarking on your job search in the UK. From crafting a compelling CV and assembling a Sample Portfolio to working out salary expectations and selecting suitable cities to consider, I will delve into crucial aspects that will assist you in navigating the entire process. I will also provide guidance on how to handle your Architect job title.</p>
          <p/>
          <p/>
          <h2>The Structure of this Article</h2>
          <ol>
          <li>ARB, RIBA and Job Titles</li>
          <li>Locations</li>
          <li>Salaries&nbsp;& Skilled Worker Visa</li>
          <li>Finding Architecture Jobs in the UK</li>
          <li>Application documents - CV, Portfolio and Cover Letters</li>
          <li>Frequently Asked Questions&nbsp;</li>
          </ol>
          <p/>
          <p/>
          <h2>Section 1: Job Titles, ARB and RIBA</h2>
          <p/>
          <p/>
          <h3>What is the Architects Registration Board (ARB)</h3>
          <p>&ldquo;<em>The Architects Registration Board (ARB) regulates the architects&rsquo; profession in the UK to ensure that good standards of conduct and practice are consistently maintained. ARB is a public corporation of the Department for Levelling Up, Housing and Communities</em><em>.</em>&rdquo; Source: <a href="https://www.gov.uk/government/organisations/architects-registration-board#:~:text=The%20Architects%20Registration%20Board%20(ARB,Levelling%20Up%2C%20Housing%20and%20Communities%20." target="_blank" rel="noopener">Gov.UK&nbsp;</a></p>
          <p>Note: In the UK, only the job title "Architect" is protected, not the function itself.</p>
          <p/>
          <p/>
          <h3>Can I Use the Title of &ldquo;Architect&rdquo; Without ARB Registration?</h3>
          <p>No, it's important not to use the title of &ldquo;Architect&rdquo; unless you are registered with the ARB. Anyone can verify whether you are registered with the ARB - all someone has to do is search the ARB register. Keep in mind that the title of Architect is legally protected in the UK. Claiming to be an Architect when you are not registered with the ARB can lead to serious consequences and legal issues.</p>
          <p/>
          <p/>
          <h3>I&rsquo;m an Overseas Qualified Architect, how can I register with the ARB?</h3>
          <p>&ldquo;<em>Currently if you hold overseas qualifications obtained outside the EU or hold non-recognised UK qualifications, you will need to pass ARB&rsquo;s Examination for Equivalence to Prescribed Qualifications (also known as the &lsquo;Prescribed Examination&rsquo;) to progress towards registering as an architect in the UK.</em></p>
          <p><em>Once you have satisfied Part 1 and Part 2 requirements, either by obtaining qualifications or by passing the Prescribed Examination, you will then need to obtain a UK Part 3 qualification from a UK school of architecture. Please note if you hold EU qualifications at degree level, that is equivalent to Part 1, you will be required to sit the examination at Part 1 level as the Directive does not provide for qualifications at degree level only.</em></p>
          <p><em>For Part 1 Examination you must have successfully completed a three-year course of study; for Part 2 you must already hold Part 1 and have completed two further years of postgraduate study in architecture.</em></p>
          <p><em>If you hold qualifications that are not principally in architecture, for example, you have a degree in Interior Architecture, Interior Design or Architectural Technology, it is unlikely you will be eligible to sit the examination unless you fall under the current transitional arrangements </em><a href="https://arb.org.uk/wp-content/uploads/2016/05/Extended-Transitional-Arrangements-for-Prescribed-Examination.pdf" target="_blank" rel="nofollow noopener"><em>set out here</em>.</a>&rdquo;.</p>
          <p>Source: <a href="https://arb.org.uk/architect-information/applying-for-registration-for-the-first-time/i-hold-overseas-non-recognised-uk-qualifications/#:~:text=Currently%20if%20you%20hold%20overseas,an%20architect%20in%20the%20UK." target="_blank" rel="nofollow noopener">ARB website</a></p>
          <p/>
          <p/>
          <h3>Has the UK entered into any Mutual Recognition Agreements with other Countries?</h3>
          <p>Yes, In 2023, the ARB signed Mutual Recognition Agreements (MRAs) with the US (NCARB), Australia (AACA), and New Zealand (NZRAB), providing eligible architects with a pathway to licensure in the UK.</p>
          <p>In 2024, a new Memorandum of Understanding (MoU) between the Hong Kong Institute of Architects (HKIA) and the ARB enables the mutual recognition of qualifications, simplifying the process for eligible individuals to have their qualifications recognised in both the UK and Hong Kong.</p>
          <p>For details on eligibility and application, <a href="https://arb.org.uk/international-routes/">ARB website</a>.</p>
          <p/>
          <p/>
          <h3>Common Architecture Job Titles in the UK</h3>
          <p>We use the following job titles in the UK:</p>
          <ul>
          <li>Part 1 Architectural Assistant</li>
          <li>Part 2 Architectural Assistant</li>
          <li>Architectural Assistant</li>
          <li>Part 3</li>
          <li>Architectural Designer</li>
          <li>Architect (protected title)</li>
          <li>Landscape Architect</li>
          <li>Design Architect</li>
          <li>Project Architect</li>
          <li>Senior Architect</li>
          <li>Associate Architect/Principal Architect</li>
          <li>Director</li>
          </ul>
          <p>It's worth noting that the job title "Junior Architect" is not used in the UK, so I advise you to avoid referring to yourself as such if you are seeking a position in the UK architectural industry.</p>
          <p/>
          <p/>
          <h3>Choosing a Title if You're Not ARB Registered</h3>
          <p>If you find yourself in the situation of being an overseas qualified Architect without ARB registration, there are a few options available for how to refer to yourself. You can use the title "Overseas Qualified Architect" or opt for "Architectural Designer".</p>
          <p>It's important to note that the term "Architectural Designer" is not a protected title in the UK, giving you the freedom to adopt it.</p>
          <p>Initially, many overseas qualified Architects choose to use the title of "Architectural Designer" until they attain ARB registration. Some may decide to continue with this title if ARB registration is not currently viable due to course requirements or financial restrictions.&nbsp;</p>
          <p/>
          <p/>
          <h3>What is the difference between ARB and RIBA?</h3>
          <p>It's important to differentiate between the Architects Registration Board (ARB) and the Royal Institute of British Architects (RIBA):</p>
          <p>ARB: The regulatory body ensures architects meet requirements for practicing legally and using the "architect" title.</p>
          <p>RIBA: A voluntary professional membership organisation offering support, resources, and development opportunities.</p>
          <p>In essence, ARB registration is necessary for using the "Architect" title, while RIBA offers optional benefits and support.</p>
          <p/>
          <p/>
          <h2>Section 2: Locations</h2>
          <p/>
          <p/>
          <h3>Choosing Where to Work and Live</h3>
          <p>When you're figuring out where to work and live in the UK, it's important to do your homework. The ARB has some interesting facts about where architects live or are registered, and it's not surprising that the majority are in London. Take a look at the graph below, which displays the "Location of UK population and architects".</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Location all architects on the register.PNG" alt="Location: all architects on the Register. Half (50%) of all architects are based in London and the South East, compared to 27% of the UK population. This demonstrates a lack of geographical spread of the profession, with many regions of the UK underrepresented. The second most common location is outside the UK. Scotland is the third most common location (8%), which also has a slightly higher proportion of architects compared to the UK population. All other regions are underrepresented. Source arb.org.uk"></img>
          <p>Source: <a href="https://arb.org.uk/wp-content/uploads/ARB-EDI-Report-April-2023.pdf" target="_blank" rel="nofollow noopener">ARB</a></p>
          <p/>
          <p/>
          <h3>Considering London</h3>
          <p>Many job seekers are interested in living and working in London. It's a place with lots of job options, which might make finding a job seem easier. However, I personally don't think it's that simple. Yes, there are more architecture companies in and around London, but that also means you'll be competing with more people for jobs. So, it could take longer to find a job than you think. Also, keep in mind that living in London can be expensive, and traveling from one place to another can take more than 1.5 hours.</p>
          <p>There's a website called <a href="https://www.numbeo.com/cost-of-living/compare_cities.jsp?country1=Spain&amp;country2=United+Kingdom&amp;city1=Barcelona&amp;city2=London&amp;tracking=getDispatchComparison" target="_blank" rel="nofollow noopener">Cost of Living</a> where you can check the average costs of living in different cities across the UK. Below is an example that shows the difference between living in Barcelona and London.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Cost of living London vs Barcelona.PNG" alt="Cost of Living Comparison Between Barcelona and London. You would need around 6,714.7€ (5,807.7£) in London to maintain the same standard of life that you can have with 3,900.0€ in Barcelona (assuming you rent in both cities). This calculation uses our Cost of Living Plus Rent Index to compare the cost of living and assume net earnings (after income tax). You can change the amount in this calculation. Consumer Prices in London are 45.3% higher than in Barcelona (without rent) Consumer Prices Including Rent in London are 72.2% higher than in Barcelona Rent Prices in London are 128.2% higher than in Barcelona Restaurant Prices in London are 54.1% higher than in Barcelona Groceries Prices in London are 29.4% higher than in Barcelona Local Purchasing Power in London is 16.9% higher than in Barcelona"></img>
          <p/>
          <p/>
          <h3>Exploring Job Opportunities in Other UK Cities</h3>
          <p>If London isn't your first choice, I strongly recommend looking into cities like Manchester, Bristol, Leeds, Birmingham, Glasgow, Newcastle, and Edinburgh. These cities have many architecture and multi-disciplinary firms, and the cost of living is lower compared to London.</p>
          <p>Below, there's a comparison between the Cost of Living in <a href="https://www.numbeo.com/cost-of-living/compare_cities.jsp?country1=United+Kingdom&amp;city1=London&amp;country2=United+Kingdom&amp;city2=Manchester" target="_blank" rel="nofollow noopener">London and Manchester</a>.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/cost of living london vs manchester.PNG" alt="Cost of Living Comparison Between Manchester and London. You would need around 5,924.5£ in London to maintain the same standard of life that you can have with 3,800.0£ in Manchester (assuming you rent in both cities). This calculation uses our Cost of Living Plus Rent Index to compare the cost of living and assume net earnings (after income tax). You can change the amount in this calculation. Consumer Prices in London are 28.0% higher than in Manchester (without rent) Consumer Prices Including Rent in London are 55.9% higher than in Manchester Rent Prices in London are 119.5% higher than in Manchester Restaurant Prices in London are 26.8% higher than in Manchester Groceries Prices in London are 15.7% higher than in Manchester Local Purchasing Power in London is 8.2% lower than in Manchester"></img>
          <p/>
          <p/>
          <h3>Considering more Rural Areas in the UK&nbsp;</h3>
          <p>If you're open to it, you might think about moving to the beautiful countryside or even remote villages in the UK. Just remember a few things. There won't be as many job options around, the architecture firms will generally be smaller &ndash; meaning fewer chances for sponsorships and fewer job opportunities in general. You'll probably need a car or use public transport (just so you know, trains can be pricey in the UK), and your commute could easily be at least 1 hour each way.</p>
          <p>Also, it's good to know that some of the UK's most rural places can also be quite expensive. So, be sure to do your research.</p>
          <p/>
          <p/>
          <h2>Section 3: Salaries</h2>
          <p/>
          <p/>
          <h3>Understanding Architectural Salaries</h3>
          <p>Now, let's discuss the financial aspect. I'm not sure if you're aware, but architect salaries in the UK are generally not very high. There is also a lot of misinformation spread on the internet, so please do your research before you make any decisions to move over.</p>
          <p>If you're searching for jobs in London, the salaries are better, but remember that living costs are much higher too. I'll break down the salaries based on Job Title and whether it's in London or outside London.</p>
          <p/>
          <p/>
          <h3>What Are the Salary Ranges for Architects in the UK (2024)?</h3>
          <p/>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Job Title</strong></td>
          <td><strong>London</strong></td>
          <td><strong>Outside London</strong></td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-1-architectural-assistant-salary" target="_blank" rel="noopener">Part 1 Architectural Assistant</a></td>
          <td>&pound;23.000 - &pound;25.000</td>
          <td>&pound;19.000 - &pound;22.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-2-architectural-assistant-salary" target="_blank" rel="noopener">Part 2 Architectural Assistant</a></td>
          <td>&pound;28.000 - &pound;33.000</td>
          <td>&pound;23.000 - &pound;26.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-3-salary" target="_blank" rel="noopener">Part 3&nbsp;</a></td>
          <td>&pound;30.000 - &pound;35.000</td>
          <td>&pound;28.000 - &pound;30.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/architect-salary" target="_blank" rel="noopener">Architect</a></td>
          <td>&pound;35.000 - &pound;40.000</td>
          <td>&pound;31.000 - &pound;37.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/project-architect-salary" target="_blank" rel="noopener">Project Architect</a></td>
          <td>&pound;39.000 - &pound;45.000</td>
          <td>&pound;36.000 - &pound;39.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/senior-architect-salary" target="_blank" rel="noopener">Senior Architect</a></td>
          <td>&pound;45.000 - &pound;55.000</td>
          <td>&pound;39.000 - &pound;43.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/landscape-architect-salary" target="_blank" rel="noopener">Landscape Architect&nbsp;</a></td>
          <td>&pound;35.000 - &pound;40.000</td>
          <td>&pound;31.000 - &pound;36.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/senior-landscape-architect-salary" target="_blank" rel="noopener">Senior Landscape Architect</a></td>
          <td>&pound;45.000 - &pound;55.000</td>
          <td>&pound;37.000 - &pound;43.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/associate-architect-salary" target="_blank" rel="noopener">Associate Architect</a></td>
          <td>&pound;56.000 - &pound;65.000</td>
          <td>&pound;44.000 - &pound;48.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/director-salary" target="_blank" rel="noopener">Director</a></td>
          <td>&pound;66.000 - &pound;90.000+</td>
          <td>&pound;49.000 - &pound;70.000+</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>All figures are per year and based on 35 hrs per week and not RIBA Chartered Practices.*</p>
          <p>Please note that salaries will vary between practices and locations, so consider this as a guideline. Also, if you are joining a RIBA Chartered Practice, know that they have to pay you the Living Wage, which is different from the National Minimum Wage.</p>
          <p>To learn more on this subject please read <a href="https://www.archjobs.co.uk/articles/uk-architectural-salaries-and-living-wages" target="_blank" rel="noopener">UK Architectural Salaries, Living Wages, Unpaid Overtime and more.</a></p>
          <p/>
          <p/>
          <h3>UK Skilled Worker Visa - 4th April 2024</h3>
          <p>The UK Government has announced changes to the Skilled Worker Visa salary threshold.</p>
          <p>It is confirmed that Architects/Landscape Architect (Code 2451) will have to earn £45,900 per annum. For Chartered Architectural Technologists/Architectural Consultants (Code 2452) you'll need to meet at least the minimum standard salary of £38,700 per annum. While the going rate is set at £36,200 per annum, you need to earn whichever figure is higher, in this instance, it is £38,700 per annum.</p>
          <p>If you are applying for Architectural Assistant/Architectural Technician (Code 3120) positions, you'll need to meet at least the minimum standard salary of £38,700 per annum. While the going rate is set at £31,200 per annum, remember, you need to earn whichever figure is higher. Please see if you are eligible for the New Entrant route (70% of going rate, Option E), you will only need to earn £32,130 for Architect/Landscape roles and £30,960 per annum for the other 2 Codes.</p>
          <p/>
          <p><strong>New Entrants are at least one of the following:</strong></p>
          <div>- you’re under 26 on the date you apply</div>
          <div>- you’re currently in the UK on a Student visa studying at bachelor’s degree level or above - or you have been in the last 2 years, and a Student or visit visa was your most recent visa</div>
          <div>- you’re currently in the UK on a Graduate visa, or you have been in the last 2 years you’ll be working towards a recognised qualification in a UK-regulated profession</div>
          <div>- you’ll be working towards full registration or chartered status in the job you’re being sponsored for</div>
          <p/>
          <p><strong>Options explained:&nbsp;</strong></p>
          <p><strong>Options A-E</strong> are if you are applying for the Skilled Worker Visa for the first time, and you received your CoS after 4th April 2024.</p>
          <p><strong>Option A</strong> - The applicant’s salary equals or exceeds both: £38,700 per year; and or the going rate for the SOC 2020 occupation code.</p><p>Option B &amp; C - PhD points related</p>
          <p><strong>Option D</strong> - If the role on the Immigration Salary List (which has been removed - not applicable anymore)</p>
          <p><strong>Option E</strong> - New Entrant</p>
          <p>In this case, the different options don’t result in a salary deduction as the minimum salary to qualify for a skilled worker visa is £30,960.</p>
          <p><strong>Options F-J</strong> are if you received your CoS before 4th April, you can make a Skilled Worker Visa application under the old rules and then switch, extend or settle at a later date.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Optionsa-e.png" alt="Screenshot of the Option A-E"></img>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/optionsf-j.png" alt="Screenshot of the Option F-J"></img>

          <p>Source: <a href="https://assets.publishing.service.gov.uk/media/65f18e57ff11701fff6159bb/E03091226_-_HC_590_-_Immigration_Rules_Changes__Web_Accessible_.pdf" target="_blank" rel="noopener">STATEMENT OF CHANGES IN IMMIGRATION RULES</a></p>
          <p/>
          <p/>
          <h2>Section 4: Finding Architecture Jobs in the UK</h2>
          <p>There are multiple ways to discover job opportunities in the UK:</p>
          <ol>
          <li>Through a job board or platform that allows you to apply for roles</li>
          <li>Via the company's website (usually through their Application Tracking System - ATS)</li>
          <li>Utilising your own network</li>
          <li>Using LinkedIn</li>
          <li>Working with an architectural Recruiter.</li>
          </ol>
          <p><strong>1. Applying Through Job Boards</strong></p>
          <p>In the UK, we have numerous job boards like Indeed, CV Library, Monster, LinkedIn, and more. However, I recommend using job boards that are specific to architecture, such as RIBA Jobs, RIAS, and <a href="https://www.archjobs.co.uk/search" target="_blank" rel="noopener">ArchJobs</a>.</p>
          <p>One thing I want to highlight is that when you search for an Architect role on more general job boards like Indeed, you might come across a lot of IT-related Architect positions. This can be a bit confusing at first. Roles like Solutions Architect, Information Architect and Naval Architect are not related to architectural positions in the Build Environment. So, remember this while searching through larger job boards.</p>
          <p/>
          <p><strong>2. Applying Through the Company Website</strong></p>
          <p>At times, architectural firms will post job openings on their websites. Larger companies usually use an internal recruitment platform which lists all open positions, also known as an Application Tracking System (ATS). This system assists them in managing, tracking, and organising the applications they receive.</p>
          <p>Smaller practices, on the other hand, may request you to send your application to a specific email address. You might also be interested in learning more about <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-cv" target="_blank" rel="noopener">how to make your CV compatible with ATS </a>(Application Tracking Systems).</p>
          <p/>
          <p><strong>3. Using your Own Network</strong></p>
          <p>Many people either forget or don't realise how valuable their network can be. Reach out to former colleagues, university friends or family members. Connect with people on LinkedIn and see if anyone has job leads or suggestions. You might even attend networking events in your area &ndash; you never know who you could meet. Stay open-minded and be willing to explore opportunities through networking.</p>
          <p/>
          <p><strong>4. Using LinkedIn</strong></p>
          <p>LinkedIn is an incredible and powerful professional platform with:</p>
          <ul>
          <li>Over 58 million companies registered on LinkedIn</li>
          <li>LinkedIn has more than 830 million members</li>
          <li>49 million people use LinkedIn to find a new job every week</li>
          <li>8640 people are hired through LinkedIn every day</li>
          <li>77% of recruiters use it to find new candidates&nbsp;</li>
          </ul>
          <p>Source: <a href="https://blog.hootsuite.com/linkedin-statistics-business/" target="_blank" rel="nofollow noopener">Hootsuite</a></p>
          <p>I would highly advise to have a LinkedIn profile, and you can also explore job opportunities on their platform.</p>
          <p>To discover <a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">more about crafting an effective LinkedIn profile, you can click here</a>.</p>
          <p/>
          <p><strong>5. Working with a Recruiter</strong></p>
          <p>Working with a recruiter can be helpful if you have experience in the UK, have worked with well-known companies, or possess specific skills in areas like Healthcare, Data Centres, or Higher Education. If this applies to you, it's worth getting in touch with a recruiter.</p>
          <p>However, one thing to keep in mind: if you require sponsorship, it can be challenging to secure, as overall hiring costs for the practices are becoming too high. Not only would the practice need to pay the recruiter, but they would also need to cover your sponsorship.</p>
          <p>To learn more about <a href="https://www.archjobs.co.uk/articles/how-to-find-an-architectural-recruiter-and-work-with-one" target="_blank" rel="noopener">finding an architectural recruiter, you can read this article</a>.</p>
          <p/>
          <p/>
          <h2>Section 5: Application Documents - CV, Portfolio and Cover Letters</h2>
          <p/>
          <p/>
          <h2>CV</h2>
          <p/>
          <p/>
          <h3>General CV Formatting Tips</h3>
          <div>File type: PDF&nbsp;</div>
          <div>File size: Max 5MB</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: Ideally 1 - 2</div>
          <div>Structure: Always start with your most recent work experience</div>
          <div>Naming your file: First and last name - CV. (i.e. Jane Doe - CV)&nbsp;</div>
          <p/>
          <p/>
          <h3>Basic Structure of a UK CV</h3>
          <p>After looking through thousands of CVs in my time, I&rsquo;ve noticed one particular CV structure which seems to be liked by most hiring managers.&nbsp;</p>
          <p>A rough outline is as follows:</p>
          <ol>
          <li>Full name, your Job Title (i.e. Overseas Qualified Architect or Architectural Designer)</li>
          <li>Personal Details (phone, email and city)&nbsp;</li>
          <li>About Me/Profile section (quick snapshot of who you are, your level of experience, what sectors you've worked on, software etc.)</li>
          <li>Soft-Skills section - for example, job running, presentation, leadership, design, and team player (only list the most relevant skills)</li>
          <li>Software &amp; Tools section - for example, Revit, AutoCAD, Photoshop, Indesign, SketchUp, ArchiCAD, Vectorworks, Microsoft Teams</li>
          <li>Working History - begin with your most recent experience (this is very important)</li>
          <div>6.1. Job Title, Company name, Location, Dates (start - finish)</div>
          <div>6.2. Summarise your responsibilities and mention any work highlights and provide hard facts and figures&nbsp;</div>
          <li>Hobbies (optional and avoid using just images/icons)</li>
          <li>Education/Awards&nbsp;</li>
          <li>References - Available upon Request</li>
          </ol>
          <p/>
          <p/>
          <h3>What to Include in my CV?</h3>
          <p>Initially, hiring managers and recruiters will focus on key details such as your job title, location, work experience, the architectural practices you've been a part of, and your proficiency with specific software skills.</p>
          <p/>
          <p/>
          <h3>What not to Include in my CV</h3>
          <p>When writing your CV, it's important to note that different countries have their own distinct styles and required information. In the UK, there are certain details that you do not need to include. Specifically, there's no requirement to provide a photograph of yourself, passport details, marital status or information about whether you have children.</p>
          <p>If you'd like to learn more about <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">writing and structuring an architecture CV</a>, or if you're <a href="https://www.archjobs.co.uk/articles/how-to-write-a-senior-architectural-cv" target="_blank" rel="noopener">an experienced Architect seeking additional insights</a>, be sure to read my other articles.</p>
          <p/>
          <p/>
          <h2>Portfolios&nbsp;</h2>
          <p/>
          <p/>
          <h3>What is a Portfolio?</h3>
          <p>An architectural portfolio is a unique body of work which represents your experience as a Designer. A portfolio should be used to showcase your best work that you can share with potential employers or clients. It provides a true reflection of your architectural skills, your design approach, technical abilities and how you have evolved in your career.</p>
          <p/>
          <p/>
          <h3>In the UK, we use 2 Types of Portfolios</h3>
          <p>First, I want to clarify that we use 2 types of Portfolios in the UK. One is your <strong>Sample Portfolio </strong>and the other your <strong>Full Portfolio</strong>. When you are applying for new architectural positions, ensure you attach your Sample Portfolio and not your Full Portfolio.</p>
          <p>This goes for everyone, whether you&rsquo;re a student or Senior Architect, having a Sample Portfolio is a must-have.&nbsp;</p>
          <p>You can also read what Andy Shaw, Managing Partner at AMA thinks about Sample Portfolios.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Andy Shaw - Portfolio.PNG" alt="Screenshot - Andy Shaw Managing Partner at AMA, Chair of RIBA Gulf Chapter. Very good advice Aylin Round - as an employer this is what I am looking for. A few pages of the best work is enough, don't have time to go through 30 pages when so many come in. Even more - if someone emails in a crazy big file over 20MB it's a red flag that they don't know how to handle digital information well."></img>
          <p>To read the full LinkedIn post, <a href="https://www.linkedin.com/feed/update/urn:li:activity:7092051336413724672/" target="_blank" rel="nofollow noopener">click here.</a></p>
          <p/>
          <p/>
          <h3>General Formatting Tips for Sample and Full Portfolios</h3>
          <p><strong>For the Sample Portfolio, consider the following:</strong></p>
          <div>Length: Ideally, under 10-12 pages</div>
          <div>File type: PDF</div>
          <div>File size: Under 10MB</div>
          <div>Cover Page: Include your Full Name, Job Title, Phone number and Email&nbsp;</div>
          <div>Font type: Easy-to-read fonts like Arial, Times New Roman, or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Structure: Begin with your most recent work</div>
          <div>File naming: First and last name - Sample Portfolio (e.g. Jane Doe - Sample Portfolio)</div>
          <p/>
          <p><strong>The Full Portfolio shares similarities but with three differences:</strong></p>
          <div>Length: Up to 40-50 pages</div>
          <div>File size: Under 20MB</div>
          <div>File naming: First and last name - Portfolio (e.g. Jane Doe - Portfolio)</div>
          <p/>
          <p><strong>💡 Tip:</strong> If you are a more experienced Architect with 20+ years of experience, focus on the last 5-7 years. Hiring managers and recruiters will be more interested in your recent experience and not what you&rsquo;ve worked on 20 years ago.&nbsp;</p>
          <p>To learn <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">how to prepare and what to include in an Architectural Portfolio, click here</a>.</p>
          <p/>
          <p/>
          <h3>Want to Improve your Technical Skills and Enhance Your Portfolio?</h3>
          <p>At ArchJobs, we are always looking for ways to support our community. One of the main challenges you'll face as an Overseas Professional is the lack of UK experience, which can slow down your job search.</p>
          <p>That's why we've partnered with <a target="_blank" href="http://www.archademia.com?utm_source=ArchJobs&utm_medium=Article">ArchAdemia</a>, who share our mission of providing architectural professionals with the technical skills they need to advance in their careers.</p>
          <p>For professional, ArchAdemia is your bridge from theory to practice. Software tutorials under the guidance of industry professionals will give you the capability to make an impact from day 1. Master the art of drawing, manage projects like a pro, and immerse yourself in podcasts that give you the inside scoop from those who've paved the way.</p>
          <p>But ArchAdemia isn't just tutorials and resources. It's a vibrant community where passions meet, discussions flourish, and connections are forged. It's a space where students and professionals alike share, learn, and grow together, transcending traditional boundaries.</p>
          <p>With 35 meticulously curated <a target="_blank" href="http://www.archademia.com/lessons?utm_source=ArchJobs&utm_medium=Article">courses</a>, offering everything from beginner basics to advanced expertise in parametric design and BIM, we will help you to master architecture. Whether you're refining your software skills or diving deep into design theory, we’ve got you covered.</p>
          <p>To get <a target=" blank" href="https://archademia.com/pricing/"><strong>10% off your Annual Membership subscription</strong></a>, simply enter <strong>ARCHJOBS10</strong>. </p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Course Display.jpg" alt="Screenshot from ArchAdemia website - The image is a screenshot of the homepage of a website that offers courses in 3D design, architecture, and animation software. The homepage features a grid of cards, each of which represents a different course. The cards include the course title, a brief description, and an image. Some of the courses featured on the homepage include: SketchUp Complete Guide, The Perfect Floor Plan,Rhino & Grasshopper Essentials and much more."></img> </p> 
          <p/>
          <p/>
          <h2>Cover Letters&nbsp;</h2>
          <p/>
          <p/>
          <h3>What is a Cover Letter?</h3>
          <p>&ldquo;<em>The cover letter is a formal business letter which is often the first contact with a prospective employer. It serves as an introduction to you and your background experience. Since it is usually the first impression you make on the employer, you want it to be your best.</em>&rdquo;&nbsp;</p>
          <p>Source: <a href="https://www.oxy.edu/sites/default/files/assets/cover-letters_0.pdf" target="_blank" rel="nofollow noopener">Oxy.edy</a></p>
          <p/>
          <p/>
          <h3>Why Cover Letters matter</h3>
          <p>Whether you like them or not, sometimes you'll need to include a Cover Letter. Even though many job seekers don't enjoy writing them and think it's a big waste of time, if a practice asks for a short cover letter in their job ad, you should definitely send one. Otherwise, you might not be considered as a possible candidate. Wondering why? It's because sometimes companies get lots of applications, and asking for a cover letter is an easy way to find out who can follow simple directions.</p>
          <p/>
          <p/>
          <h3>General Cover Letter Formatting Tips</h3>
          <div>File type: Either PDF or email</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: Max 1 - ideally only half a page</div>
          <p/>
          <p><strong>💡 Tip:</strong> Avoid combining your Cover Letter, CV and Sample Portfolio into 1 PDF file. Your application will come across way more generic to hiring managers and recruiters.&nbsp;</p>
          <p/>
          <p/>
          <h3>Basic Structure of your Cover Letter (plus example)</h3>
          <p>You should structure your Cover Letter in 4 parts.</p>
          <ol>
          <li>Why are you contacting them</li>
          <li>Why would you like to work for them</li>
          <li>Highlight your skills</li>
          <li>Summarise&nbsp;</li>
          </ol>
          <p><strong>The first paragraph</strong> in your Cover Letter should include why you&rsquo;re contacting them, the position you are applying for and where you saw the role advertised.&nbsp;</p>
          <p><strong>Second paragraph</strong>, explain to them why you would like to work for them (do you like the projects they work on, have they recently won any awards, have you been following the practice for a while etc.) and what skills you offer.&nbsp;</p>
          <p><strong>Third paragraph</strong>, highlight any of your key Skills and show them what you could bring to the table i.e. Revit, UK Building Regulations, Job running, Strong communicator, Site visits, Attention to detail, and team player.&nbsp;</p>
          <p><strong>The fourth paragraph</strong>, summarise why you&rsquo;re interested and why you&rsquo;re the right fit for the role.</p>
          <p/>
          <p><strong>💡 Tip:</strong> Before you send in your application, try to find out the name of the person in charge of hiring, like the hiring manager or director. This is a good way to make a positive impression and show that you put effort into getting your application ready. You can look on their website or LinkedIn to see who to address it to. If you're not sure, it's okay to address it as &ldquo;Dear Hiring Manager&rdquo;.</p>
          <p/>
          <p/>
          <h3>Should I Tailor My Cover Letter?</h3>
          <p>Yes, I would highly recommend spending that extra time amending your Cover Letter. Hiring managers can tell if a Cover Letter has been tailored to a specific job or not.&nbsp;</p>
          <p>Don&rsquo;t forget, first impressions count and you only have their attention for a short time. Make every application count - more time spent on tailoring your applications will reduce your job search time massively.</p>
          <p>To find more information on <a href="https://www.archjobs.co.uk/articles/how-to-structure-an-architecture-cover-letter" target="_blank" rel="noopener">Cover Letters and read a few examples, you can click here</a>.</p>
          <p/>
          <p/>
          <h2>Section 6: Frequently Asked Questions</h2>
          <p/>
          <p/>
          <h3>Can I use the Architect without being registered with the ARB?</h3>
          <p>No, you can't call yourself an Architect unless you're registered with the ARB. Anyone can verify if you're ARB registered. Remember, the title Architect is protected in the UK. Using it without ARB registration can lead to legal issues.</p>
          <p/>
          <p/>
          <h3>How many Registered Architects are in the UK</h3>
          <p>In the UK, there are roughly 42,000 registered Architects with a population of 67 million.</p>
          <p/>
          <p/>
          <h3>Do I need to be registered with both the ARB and the RIBA?</h3>
          <p>No, it's not necessary to hold registrations with both the ARB and the RIBA. To use the protected title "Architect" and gain inclusion in the ARB register, successful completion of the RIBA's Part 3 exams is essential. However, being registered with RIBA is not required.</p>
          <p/>
          <p/>
          <h3>Will I have to pay a fee to the ARB and the RIBA?</h3>
          <p>Yes, there are fees for registering with both the <a href="https://arb.org.uk/architect-information/maintaining-registration/annual-retention-fee/fees/" target="_blank" rel="noopener">ARB</a> and the <a href="https://www.architecture.com/join-riba/chartered-member-fees" target="_blank" rel="noopener">RIBA</a>. If you choose to be registered with both groups, you will need to pay fees to both the ARB and the RIBA. The fees for RIBA membership can be different depending on the type of membership you pick. Usually, being a student member with RIBA doesn't cost anything.</p>
          <p/>
          <p/>
          <h3>What is an AJ100 Practice?</h3>
          <p>The AJ100 is usually a yearly survey and ranking of the biggest architecture firms in the UK. It's published by the Architects' Journal (AJ). The ranking is based on the number of qualified architects employed by these firms. These practices play a major role in the UK's architectural scene and often work on diverse projects in different sectors.</p>
          <p>If you're curious, you can find a list of the <a href="https://www.architectsjournal.co.uk/aj100-data-and-profiles" target="_blank" rel="noopener">100 largest architectural practices</a>, also known as AJ100.</p>
          <p/>
          <p/>
          <h3>Do All Practices Offer Sponsorships In The UK?</h3>
          <p>No, not all practices offer sponsorships. As a general guideline, smaller practices are less likely to provide sponsorships. The best thing you can do is check the UK Government's “<a target="_blank" rel="noopener" href="https://www.gov.uk/government/publications/register-of-licensed-sponsors-workers">Register of licensed sponsors: workers</a>” list. It's a large file, but it will show you if a practice is in a position to offer sponsorship.</p>
          <p/>
          <p/>
          <h3>I require Sponsorship, can a Recruiter help me find a Job?</h3>
          <p>Finding an architectural recruiter willing to assist candidates requiring sponsorship can be challenging. The primary challenge lies in the high costs associated with sponsoring candidates. Not only does the practice need to cover the recruiter's fees, but they also must bear the additional expense of sponsoring the candidate.</p>
          <p/>
          <p/>
          <h3>What are the recent changes to the UK Skilled Worker Visa?</h3>
          <p>It is confirmed that Architects/Landscape Architect (Code 2451) will have to earn £45,900 per annum. For Chartered Architectural Technologists/Architectural Consultants (Code 2452) you'll need to meet at least the minimum standard salary of £38,700 per annum. While the going rate is set at £36,200 per annum, you need to earn whichever figure is higher, in this instance, it is £38,700 per annum.</p>
          <p>If you are applying for Architectural Assistant/Architectural Technician (Code 3120) positions, you'll need to meet at least the minimum standard salary of £38,700 per annum. While the going rate is set at £31,200 per annum, remember, you need to earn whichever figure is higher. Please see if you are eligible for the New Entrant route (70% of going rate, Option E), you will only need to earn £32,130 for Architect/Landscape roles and £30,960 per annum for the other 2 Codes.</p>
          <p/>
          <p/>
          <h3>What's a Good Strategy for Finding an Architectural Job in the UK?</h3>
          <p>Here's a strategy I suggest:</p>
          <ul>
          <li>Research the cities you're interested in.</li>
          <li>Check if your <a href="https://arb.org.uk/architect-information/applying-for-registration-for-the-first-time/i-hold-overseas-non-recognised-uk-qualifications/#:~:text=Currently%20if%20you%20hold%20overseas,an%20architect%20in%20the%20UK." target="_blank" rel="nofollow noopener">overseas qualification is accepted by the ARB</a>.</li>
          <li>Review the <a href="https://www.archjobs.co.uk/salary-guide/architect-salary" target="_blank" rel="noopener">salary guide</a> for different locations.</li>
          <li>Utilise your network and seek recommendations.</li>
          <li>Ensure your <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">CV</a> and <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">Sample Portfolio</a> are ready.</li>
          <li>Keep track of all your applications (company name, date, save the job description, where you applied and any other details which you'll find useful).</li>
          <li>Prioritise quality over quantity; tailor your CV and <a href="https://www.archjobs.co.uk/articles/how-to-structure-an-architecture-cover-letter" target="_blank" rel="noopener">Cover Letter</a> (if needed) for each role.</li>
          <li><a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">Update your LinkedIn</a>, including your location.</li>
          </ul>
          <p/>
          <p/> 
          <h3>Consider Contract Roles to Gain UK Experience</h3>
          <p>Many Overseas Architects face difficulty securing permanent jobs in the UK due to a lack of UK experience. Firms often value familiarity with UK Building Regulations. A solution? Also look for short-term contracts with smaller or medium-sized practices. This helps you gradually gain UK experience. Update your CV and Sample Portfolio to reflect your recent roles.</p>
          <p>Note: Contract roles are paid hourly and can span as briefly as one week. Be certain your hourly rate adequately covers your expenses.</p>
          <p/>
          <p/>
          <h3>Is Having a LinkedIn Profile Beneficial?</h3>
          <p>Absolutely, 70% of recruiters use LinkedIn to find new talent. If you already have a LinkedIn profile, keep it current, and ideally, set your location as "United Kingdom." Recruiters and hiring managers often use filters like location, job title, and software skills when looking for candidates.</p>
          <p/>
          <p/>
          <h3>Is UK Experience Really Necessary?</h3>
          <p>That's a tricky question, as I believe it depends on your skills and the type of work the practice focuses on. Some firms handle projects like Masterplan schemes in Dubai or Data Centres across the US. However, if you're applying to practices mainly engaged in UK projects, having at least some UK experience is often expected. They want candidates to understand UK Building Regulations and Standards, as well as have UK project experience.</p>
          <p/>
          <p/>
          <h3>How can I work on my Technical Skills and improve my Portfolio?</h3>
          <p>We recommend heading over to ArchAdemia and checking out their affordable courses to become part of their network. They are a trusted partner of ours, and we believe their courses will have a big impact on your career. To get <a target=" blank" href="https://archademia.com/pricing/">10% off your Annual Membership subscription</a>, simply enter <strong>ARCHJOBS10</strong>. </p>
          <p/>
          <p/>
          <h3>How Long Can It Take to Find a Job in the UK?</h3>
          <p>This can be tough to predict, depending on your experience, skills, the city you're targeting, and how actively you apply. Generally, expect the process to take at least 3-6+ months. Remember, everyone's situation is unique. The company you apply to also matters &ndash; bigger firms usually have a longer hiring process (multiple interview stages).</p>
          <p/>
          <p/>
          <h3>How Many Interview Stages Are There in the UK?</h3>
          <p>Smaller architectural firms typically have 1 or 2 interview stages. Often, the first stage is online via Teams/Zoom, followed by an in-person interview.</p>
          <p>Larger practices generally have a minimum of 2+ stages, and for senior roles, it can go up to 6, though that's rare.&nbsp;</p>
          <p/>
          <p/>
          <h3>Understanding Right to Work in the UK</h3>
          <p>For detailed and accurate information, it's best to refer to <a href="https://www.citizensadvice.org.uk/work/right-to-work-in-the-uk/check-if-you-have-the-right-to-work-in-the-uk/#:~:text=You%20automatically%20have%20the%20right,from%20the%20EU%20Settlement%20Scheme" target="_blank" rel="nofollow noopener">Citizen Advice</a> and Gov.co.uk.</p>
          <p/>
          <p/>
          <h2>Conclusion</h2>
          <p/>
          <p>Transitioning to a new job as an Overseas Qualified Architect might be tougher now, especially with the recent changes to the Skilled Worker Visa (effective 4th April 2024) making it harder to find a job in the UK. But it's still possible with the right approach. I hope this article has given you useful insights before starting this journey. From getting ARB registration to keeping your Architect title, to understanding what UK employers look for in your CV, Sample Portfolio, and Cover Letter, and realising the importance of location.</p>
          <p>It's advisable to focus on cities with a lot of opportunities and companies that might offer sponsorships if needed. Additionally, consider the aspect of salaries; architect salaries in the UK aren't exceptionally high, so it's wise to bear that in mind if you currently hold a well-paid architectural role in your home country.</p>
                  
            </ArticleFrame>
    }
}